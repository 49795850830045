






























































































import { Aluno, AlunoTurma } from "@/core/models/geral";
import { AlunoService, CashBackService } from "@/core/services/geral";
import { EnumPerfilUsuario, PageBase } from "@/core/models/shared";
import { Component, Prop, Watch, Vue } from "vue-property-decorator";

@Component
export default class ListaAluno extends PageBase {
    search: any = '';
    loading: boolean = false;
    dialogCadastro: boolean = false;
    dialogCadastroInscricao: boolean =false;
    totalLista: number = 0;
    lista: any[] = [];
    options: any = {
        sortBy:['nomeCracha'],
        itemsPerPage: 15
    };
    headers: any[] = [
        { text: '',value:'actions' ,sortable: false },
        { text: 'Cracha', value: 'nomeCracha' },
        { text: 'Nome', value: 'nome' },
        { text: 'Celular (Whatsapp)', value: 'celularWhatsapp' },
        { text: 'E-mail', value: 'email', sortable: false },
        { text: 'Indicador', value: 'usuario.indicador.complementar.nome', sortable: false },  
        { text: 'Gestor', value: 'usuario.gestor.complementar.nome', sortable: false },
        { text: 'Ativo', value: 'ativo', type:'bool' }
    ];
    item = new Aluno();
    itemInscricao = new AlunoTurma();
    service = new AlunoService();    

    masterEnum = EnumPerfilUsuario.Master;

    @Watch('options', { deep: true })
    Atualizar(){
        const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
        this.loading = true;
        this.service.Listar(page, itemsPerPage, sortBy, sortDesc, this.search, this.headers, undefined, "Usuario.Indicador, Usuario.Gestor").then(
        res => {
            this.lista = res.data.items;
            this.totalLista = res.data.count;
        },
        err => {
            if (!err.response){
                if(!navigator.onLine){
                    this.$swal("Aviso", "Não foi possível se conectar com a internet", "error");
                }
                else{
                    this.$swal("Aviso", "Não foi possível acessar a API", "error");
                }
            }
            else{
                this.$swal("Aviso", err.response.data, "error");
            }
        })
        .finally(() => (this.loading = false));
    }

    DesejaInscrever(alunoId: number, aluno: Aluno){
        this.$swal({    
            title: 'Atenção, aluno cadastrado!',
            text: 'Deseja seguir com a inscrição?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            showCloseButton: true,
            showLoaderOnConfirm: true,
        preConfirm: () => {
            this.dialogCadastroInscricao = true;
            this.itemInscricao = new AlunoTurma();
            this.itemInscricao.alunoId = alunoId;
            this.itemInscricao.aluno = aluno;
            this.itemInscricao.usuarioId = this.app.usuarioId;
        },
        // @ts-ignore: Unreachable code error
        allowOutsideClick: () => !this.$swal.isLoading()
        })
    }

    InscricaoAluno(alunoId: number, aluno: Aluno){
        this.dialogCadastroInscricao = true;
        this.itemInscricao = new AlunoTurma();
        this.itemInscricao.alunoId = alunoId;
        this.itemInscricao.aluno = aluno;
        this.itemInscricao.usuarioId = this.app.usuarioId;
    }

    Cashback(item: Aluno){
        let routeLink = this.$router.resolve(
            {
                name: 'impressaoCashBackAluno',
                query: {
                    alunoId: item.id.toString()
                }
            }
        );
        window.open(routeLink.href,'_blank')
    }

    AbrirDialogCadastro(item?: Aluno){
        if(item){
            this.service.ObterPorId(item.id, "Endereco.Municipio.Estado, Usuario.Indicador, Usuario.Gestor").then(
                res=>{
                    this.item = res.data;
                    this.item.nascimento = this.item.nascimento?.toDateYYYYMMDD();
                    this.dialogCadastro = true;
                },
                err => {
                    if (!err.response){
                        if(!navigator.onLine){
                            this.$swal("Aviso", "Não foi possível se conectar com a internet", "error");
                        }
                        else{
                            this.$swal("Aviso", "Não foi possível acessar a API", "error");
                        }
                    }
                    else{
                        this.$swal("Aviso", err.response.data, "error");
                    }
                }
            )
        }
        else{
            this.item = new Aluno();
            
            if (this.app.role != EnumPerfilUsuario.Master) {
                this.item.usuario.gestorId = this.app.usuarioId;
            }
            
            this.dialogCadastro = true;
        }
    }

    Excluir(item: Aluno){
        this.$swal({    
            title: 'Atenção!',
            text: 'Tem certeza que deseja excluir o registro atual?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            showCloseButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return this.service.Excluir(item.id)
                .then(
                    res => {
                        if (res.status == 200){
                            return res.data;
                        }
                    },
                    err => {
                        if (err.response.status == 403){
                            this.$swal("Aviso", "Você não tem permissão para essa funcionalidade!", "warning");                
                        }
                        else{
                            this.$swal('Aviso', err.response.data, 'error')
                        }
                    }
                )
                },
            // @ts-ignore: Unreachable code error
            allowOutsideClick: () => !this.$swal.isLoading()
        })
        .then((result) => {
            if(result.value){
                this.$swal("Aviso", result.value, "success");
                this.Atualizar();
            }
        })
    }
    
    AbrirDetalhes(id:number, rota:string){
        let routeLink = this.$router.resolve({name:rota,params:{id:id.toString() } });
        window.open(routeLink.href,'_blank')
    }
}
